import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import ContactUsForm from "../../components/contact-us-form"
import { Link } from "gatsby"
import profilePhoto from "../../images/robb-keller.jpg"
import PageHeader from "../../components/page-header"
import { Row, Col, Alert, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrophy } from "@fortawesome/free-solid-svg-icons"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Robert Keller | RWK Attorneys" defer={false}>
			<script type="application/ld+json">{JSON.stringify({
				"@context": "http://schema.org/",
				"@type": "Person",
				"name": `Robert Keller`,
				"jobTitle": "Bankruptcy Attorney",
				"telephone": "12058332589",
				"url": "https://rwkattorneys.com/attorneys/robert-keller"
			})}</script>
		</Helmet>

		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/attorneys">Attorneys</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Robb Keller</li>
						</ol>
					</nav>
					<h1>Robert Keller</h1>
					<p className="lead text-muted">Partner</p>
				</Col>
			</Row>
		</PageHeader>

		<div className="container mt-3">
			<div className="row">
				<div className="col-md-3 text-center">
					<img src={profilePhoto} className="img-fluid" />
					<Button variant="outline-secondary" size="sm" block className="mt-3" href="https://www.alabar.org/lib/vcards.php?first=Robert&last=Keller&middle=Clois&prefix=Mr.&suffix=&org=Russo%20White%20%26%20Keller%2C%20PC&address=315%20Gadsden%20Hwy%20Ste%20D&address2=&city=Birmingham&state=AL&zip=35235&phone=%28205%29%20833-2589&fax=%28205%29%20833-8220&email=rjlawoff%40bellsouth.net&url=">Download vCard</Button>
					<Button variant="outline-secondary" size="sm" block className="mt-1" as={Link} to="/contact">Contact</Button>
				</div>
				<div className="col-md-6">
					<Alert variant="info" className="d-flex justify-content-around align-items-center">
						<FontAwesomeIcon icon={faTrophy} className="fa-lg m-3" />&nbsp;
						<p className="mb-0">Recognized as one of Birmingham's Top Attorneys in Bankruptcy &amp; Consumer Law by Birmingham Magazine!</p>
					</Alert>

					<h2>About</h2>


					<p>Robert is owner of Russo, White &amp; Keller, P.C. He joined the firm in August 2000. He currently handles cases in bankruptcy, bankruptcy litigation, personal injury, corporate formation, and general litigation.  Immediately before coming to the Firm in 2000, he was a solo practitioner, focusing on bankruptcy and automobile fraud cases. Before then, he practiced with Dunaway & Greenway, where he handled bankruptcy cases.  He also speaks regularly at seminars regarding bankruptcy matters.  Robert attends Hunter Street Baptist Church.</p>


					<dl class="row">

						<dt class="col-sm-3">Education</dt>
						<dd class="col-sm-9">
							<p>University of Alabama School of Law, J.D. (1999)</p>
							<p>University of Alabama, B.A. English (1996)</p>
						</dd>

						<dt class="col-sm-3">Bar Admissions</dt>
						<dd class="col-sm-9">
							<p>Alabama (all state courts)</p>
							<p>Eleventh Circuit Court of Appeals</p>
							<p>Northern District of Alabama</p>
							<p>Middle District of Alabama</p>
						</dd>

						<dt class="col-sm-3">Professional Associations &amp; Memberships</dt>
						<dd class="col-sm-9">
							<p>Chair of the Bankruptcy and Commerical Law Section of the Birmingham Bar</p>
							<p>Director of the Southeast Bankruptcy Law Institute</p>
							<p>National Association of Consumer Bankruptcies (NACBA)</p>
							<p>Birmingham Bar Association</p>
							<p>Alabama State Bar</p>
						</dd>

						<dt class="col-sm-3">Honors, Awards, and Other Notables</dt>
						<dd class="col-sm-9">
							<p>AV Preeminent Rating in both legal ability and ethical standards</p>
							<p>Best Brief Award &amp; Semi-Finalist for the Bankruptcy Moot Court Team, Judge Conrad B. Duberstein National Bankruptcy Moot Court Competition</p>
							<p>Published Author</p>
						</dd>
					</dl>
				</div>

				<div className="col">
					<div className="bg-secondary border text-center mb-5">
						<h2 className="my-3" style={{ fontSize: '1rem' }}>Areas of Practice</h2>

						<ul class="list-group m-3">
							<li class="list-group-item">Bankruptcy Litigation</li>
							<li class="list-group-item">Personal Bankruptcy</li>
							<li class="list-group-item">Business Bankruptcy</li>
							<li class="list-group-item">Creditor Harassment</li>
							<li class="list-group-item">Class Actions</li>
							<li class="list-group-item">Personal Injury</li>
							<li class="list-group-item">Corporate Formation</li>
							<li class="list-group-item">General Litigation</li>
						</ul>
					</div>
					<ContactUsForm />
				</div>
			</div>
		</div>
	</Layout>
)